import './App.css';
import Project from './Project';
import ScriptInput from './ScriptInput';

function App() {
  return (
    <div className="App">
      <div style={ { color: 'transparent', height: 0 } }>.</div> { /* bypass the bug when margin the first element of <div> */ }
      <h1 className='title'>👋 Hello, It's <span className='name' >Stormyz</span></h1>
      <h3 style={ { marginTop: '10px' } }>A fullstack developer</h3>

      <ScriptInput />

      <h2 className='projects-title'>My projects:</h2>

      <div className='projects'>

        <Project link='https://kiube.stormyz.xyz' title='Kiube' desc='My 1v1 cube game in beta.' />

        <Project link='https://www.npmjs.com/package/tricks.js' title='Tricks.js' desc='My npm library.' />

      </div>
    </div>
  );
}

export default App;
