function Project(props) {
  return (
    <button onClick={() => {
      window.open(props.link, '_blank')
    }} className='proj'>
      <span className='proj-title'>{props.title}</span>
      <span className='proj-desc'>{props.desc}</span>
    </button>
  )
}

export default Project